<template>
    <v-alert text color="success">
        All done, now wait until shipment is delivered!
    </v-alert>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
  name: 'StepDelivery',
  key: 'delivery',
  label: 'Delivery',
  watch: {
    active: {
      handler (isActive) {
        if (isActive) {
          console.debug('done', this.results)
        }
      }
    }
  },
  data: () => ({}),
  mounted () {},
  methods: {}
}
</script>

<style scoped>

</style>
