export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    results: {
      type: Object,
      default: {}
    }
  },
  methods: {
    result: () => true,
    complete (event, result = true) {
      this.$emit('complete', {
        key: this.key,
        result,
        event
      })
    },
    cancel (event, result = false) {
      this.$emit('cancel', {
        key: this.key,
        result,
        event
      })
    }
  },
  computed: {
    key () {
      return this.$options.key
    }
  }
}
