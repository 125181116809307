<template>
    <div align="center">
        <h1 class="mt-8">Contactless delivery</h1>
        <v-container v-if="loading" style="height: 400px;">
            <v-row class="fill-height" align-content="center" justify="center">
                <v-col class="subtitle-1 text-center" cols="12">
                    Loading...
                </v-col>
                <v-col cols="6">
                    <v-progress-linear
                            color="blue accent-4"
                            indeterminate
                            striped
                            rounded
                            height="6"
                    ></v-progress-linear>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-else-if="terminal">
            <v-alert text color="info" class="ma-4">
                <p>Welcome to contactless delivery.</p>
                <p class="blue-grey--text">Here will be some description of the process...<br>Perhaps the same image as on the poster?</p>
                To start delivery process, press start!
            </v-alert>
            <v-btn class="mt-8" large color="success" @click="tryFullscreenStart">Start</v-btn>
        </v-container>
        <v-container v-else>
            <v-alert text color="error" class="ma-4">
                Sorry, such terminal does not exist!
            </v-alert>
        </v-container>
    </div>
</template>

<script>
import screenfull from 'screenfull'
import UAParser from 'ua-parser-js'
import QStepApi from '@/libraries/Api/QStep/Api'

const uaParser = new UAParser()
const device = uaParser.getDevice()

export default {
  name: 'Splash',
  data: () => ({
    loading: true,
    terminal: null
  }),
  mounted () {
    this.$api = new QStepApi()

    this.$api.get('/v1/terminals/' + this.$route.params.terminal_id)
      .then(response => {
        this.terminal = response.data || false
      })
      .finally(_ => {
        this.loading = false
      })
  },
  methods: {
    tryFullscreenStart () {
      device.type !== undefined && screenfull.isEnabled
        ? screenfull.request().finally(this.start)
        : this.start()
    },
    start () {
      this.$router.push({
        name: 'Start contactless delivery',
        params: {
          terminal_id: this.terminal.id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
