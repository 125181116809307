<template>
  <div>
    <v-alert type="error" class="ma-0 text-left accept-delivery-error" text v-if="error">
      {{ error }}
    </v-alert>

    <TextInput v-model="name" :required="true" id="name" label="Name"></TextInput>

    <SignaturePad
        v-if="active"
        id="delivery-signature"
        label="Signature"
        :required="true"
        ref="signaturePad"
        @change="onSignatureChange"
    />
    <v-footer fixed>
      <v-col class="text-center" cols="12">
        <v-btn large color="default" class="mr-4" @click="cancel">
          <v-icon>mdi-chevron-left</v-icon>
          Back
        </v-btn>
        <v-btn large color="primary" @click="acceptDelivery($event)" :disabled="signature === null">Accept
          delivery
        </v-btn>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
import mixin from './mixin'
import SignaturePad from '@/components/Core/Input/SignaturePad'
import TextInput from '@/components/Core/Input/Text'
import QStepApi from '@/libraries/Api/QStep/Api'

export default {
  mixins: [mixin],
  components: { SignaturePad, TextInput },
  name: 'StepSign',
  props: {
    type: Array
  },
  key: 'signature',
  label: 'Signature',
  data: () => ({
    signature: null,
    name: null,
    error: null
  }),
  mounted () {
    this.$api = new QStepApi()
  },
  computed: {
    errorMessage: {
      get () { return this.error },
      set (message) {
        this.error = message
      }
    }
  },
  watch: {
    active: {
      handler (isActive) {
        this.signature = null
      }
    }
  },
  methods: {
    onSignatureChange ({ data }) {
      this.signature = data || null
    },

    signatureData () {
      return {
        signature: this.$refs.signaturePad.getBase64Encoding(),
        extension: this.$refs.signaturePad.getExtension()
      }
    },

    acceptDelivery (event) {
      this.errorMessage = null

      const shipments = this.$store.getters.contactlessDeliveryData.shipments
      const terminalId = this.$store.getters.contactlessDeliveryData.terminalId

      const shipmentIds = []
      shipments.forEach(function (shipment) {
        shipmentIds.push(shipment.id)
      })

      const deliveryAcceptedPromise = this.$api.contactlessAcceptDelivery(this.signatureData(), this.name, shipmentIds, terminalId, true)

      deliveryAcceptedPromise.then(response => {
        this.complete(event)
      }).catch(error => {
        this.errorMessage = error.isCancel() ? false : error.message
      })
    }
  }
}
</script>

<style scoped>
.accept-delivery-error {
  margin-bottom: 20px !important;
}
</style>
