<template>
    <v-card class="shipment-card" v-bind="$attrs">
        <v-list :dense="dense">
            <v-list-item>
                <v-list-item-avatar class="my-0">
                    <v-icon>mdi-airplane-landing</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-subtitle>
                        <strong>DLV-{{ shipment['pinCode'] }}</strong>
                    </v-list-item-subtitle>
                    <v-list-item-title class="title">{{ shipment['master'] }}</v-list-item-title>
                    <v-list-item-subtitle v-if="shipment['house']">
                        HAWB: {{ shipment['house'] || '-' }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-avatar class="my-0">
                    <v-icon>mdi-package-variant-closed</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    {{ shipment['pieces'] }} pcs
                </v-list-item-content>
                <v-list-item-avatar class="my-0">
                    <v-icon>mdi-weight-kilogram</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    {{ shipment['weight'] }} kg
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <slot name="actions"></slot>
    </v-card>
</template>

<script>
export default {
  name: 'ShipmentCard',
  props: {
    shipment: {
      type: Object,
      required: true
    },
    dense: {
      type: Boolean,
      default: true
    }
  }
}
</script>
