<template>
  <div>
    <v-text-field-masked
        ref="input"
        id="deliveryPinCode"
        v-model="pinCode"
        prefix="DLV-"
        :placeholder="pinCodeMask.mask"
        persistent-hint
        :hint="shipment ? '' : 'Can be found on arrival notice'"
        :loading="loading"
        :disabled="disabled"
        :success="success || shipment !== null"
        :error-messages="errors"
        :mask="pinCodeMask"
        @complete="maskComplete"
        dense
        filled
    ></v-text-field-masked>
    <v-dialog v-if="shipment" :value="true" @keydown="onShipmentModalKeydown($event, shipment)" persistent>
      <ShipmentCard :shipment="shipment" :dense="false">
        <template v-slot:actions>
          <v-card-actions>
            <v-btn text color="error" @click="cancelShipment(shipment)">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="acceptShipment(shipment)">Accept</v-btn>
          </v-card-actions>
        </template>
      </ShipmentCard>
    </v-dialog>

    <template v-for="shipment in shipments">
      <ShipmentCard :shipment="shipment" class="mt-6" :key="shipment['pinCode']" outlined elevation="2">
        <template v-slot:actions>
          <v-btn fab x-small absolute right top color="error" @click="cancelShipment(shipment)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </ShipmentCard>
    </template>

    <v-footer fixed elevation="2">
      <v-col class="text-center" cols="12">
        <v-btn large color="success" class="mr-4" v-if="shipments.length" @click="resetInputState(true, true)">
          <v-icon>mdi-plus</v-icon>
          Add more
        </v-btn>
        <v-btn large color="primary" @click="completeShipmentsStep($event);"
               :disabled="shipments.length === 0 || shipment !== null || loading">Continue
        </v-btn>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
import _ from 'lodash'
import mixin from './mixin'
import QStepApi from '@/libraries/Api/QStep/Api'
import { CancelToken } from 'axios'
import ShipmentCard from '@/applications/ContactlessDelivery/components/ShipmentCard.vue'

export default {
  mixins: [mixin],
  components: { ShipmentCard },
  name: 'StepShipments',
  key: 'shipments',
  label: 'Shipments',
  data: () => ({
    pinCode: '',
    pinCodeMask: {
      mask: 'XXX-XXX',
      prepare: str => str.toUpperCase(),
      definitions: { X: /[a-z0-9]/i }
    },
    disabled: false,
    loading: false,
    success: false,
    errors: [],
    shipment: null,
    shipments: []
  }),
  computed: {
    errorMessage: {
      get () { return this.errors[0] || '' },
      set (message) {
        this.errors = message ? [message] : []
      }
    }
  },
  mounted () {
    this.$api = new QStepApi()
    this.$apiQuery = null
  },
  watch: {
    active: {
      handler (isActive) {
        if (isActive === true) {
          setTimeout(() => {
            this.resetInputState(this.shipments.length === 0, true)
          }, 100)
        }
      },
      immediate: true
    }
  },
  methods: {
    completeShipmentsStep (event) {
      this.$store.commit('contactlessDeliveryShipments', this.shipments)
      this.$store.commit('contactlessDeliveryTerminalId', this.$route.params.terminal_id)

      this.complete(event, this.shipments)
    },
    onShipmentModalKeydown (event, shipment) {
      switch (event.keyCode) {
        case 13: // Enter
          this.acceptShipment(shipment)
          break
        case 27:
          this.cancelShipment(shipment)
          break
      }
    },
    cancelShipment (shipment) {
      const shipmentIndex = _.findIndex(this.shipments, s => s.pinCode === shipment.pinCode)

      if (shipmentIndex !== -1) {
        this.shipments.splice(shipmentIndex, 1)
      }

      this.clear()
    },
    acceptShipment (shipment) {
      const shipmentIndex = _.findIndex(this.shipments, s => s.pinCode === shipment.pinCode)

      if (shipmentIndex !== -1) {
        this.shipments.splice(shipmentIndex, 1, shipment)
      } else {
        this.shipments.unshift(shipment)
      }

      if (this.shipment === shipment) {
        this.clear()
      }
    },
    maskComplete (value, mask) {
      this.authenticate(value)
    },
    authenticate () {
      const pinCode = this.pinCode

      this.resetInputState()
      this.errorMessage = null

      this.cancelApiQuery && this.cancelApiQuery()
      const cancelToken = new CancelToken((cancel) => {
        this.cancelApiQuery = cancel
      })

      this.loading = true
      this.$api.post('/v1/delivery/authenticate', {
        pinCode,
        terminal_id: this.$route.params.terminal_id
      }, { cancelToken })
        .then(this.authenticated)
        .catch(error => {
          this.errorMessage = error.isCancel() ? false : error.message
        }).finally(() => { this.loading = false })
    },

    authenticated ({ data }) {
      this.shipment = data.shipment
    },
    clear () {
      return this.resetInputState(this.shipments.length === 0, true)
    },
    resetInputState (focus = false, clear = false) {
      this.success = false
      this.errors = []
      this.loading = false
      this.shipment = null

      if (clear === true) {
        this.$nextTick(() => {
          this.pinCode = ''
          if (focus === true) {
            this.$refs.input.focus()
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
