<template>
    <v-stepper id="delivery-steps" v-model="stepIndex" class="fill-height">
        <v-stepper-header>
            <template v-for="(step, index) in steps">
                <v-stepper-step  :key="`${step.key}-step`" :step="index" v-bind="getStepProps(index)">
                    {{ step.label || step.name || 'Step ' + index }}
                    <small v-if="step.smallLabel">{{ step.smallLabel }}</small>
                </v-stepper-step>
                <v-divider class="divider" :key="`${step.key}-divider`"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content
                    v-for="(step, index) in steps"
                    :key="`${step.key}-content`"
                    :step="index"
                    :class="{ 'step-active': stepIndex.toString() === index }">
                <component
                        :is="step.component"
                        :active="stepIndex.toString() === index"
                        :results="completed"
                        ref="steps"
                        @complete="stepComplete"
                        @cancel="stepCancel"
                ></component>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
import _ from 'lodash'
import steps from './Steps'

const stepOptions = {
  key: '',
  label: '',
  smallLabel: '',
  editable: false,
  component: ''
}

// Convert step components to 1-indexed array with default values
let countSteps = 0
const stepsObject = _.keyBy(_.map(steps, (step, componentName) => _.defaults(
  { step: ++countSteps, component: componentName },
  _.pick(step, Object.keys(stepOptions)),
  stepOptions
)), 'step')

export default {
  name: 'Stepper',
  components: { ...steps },
  data: () => ({
    stepIndex: 1,
    // Pick only necessary keys and add defaults
    steps: stepsObject,
    completed: {}
  }),
  computed: {
    countSteps () {
      return Object.keys(this.steps).length
    },
    stepKeys () {
      return Object.keys(this.steps)
    }
  },
  methods: {
    getStepProps (stepKey) {
      const step = this.steps[stepKey]
      const props = {
        complete: !!this.completed[step.key]
      }

      if (step.key === 'welcome') {
        props.editIcon = 'mdi-information-variant'
      }

      return props
    },
    isComplete (index) {
      return this.stepIndex > index || index === 1
    },
    stepComplete ({ key, result }) {
      this.completed[key] = result
      this.advance()
    },
    stepCancel ({ key, result }) {
      if (this.completed[key]) {
        delete this.completed[key]
      }
      this.retreat()
    },
    advance () {
      if (this.stepIndex < this.countSteps) {
        this.stepIndex++
      }
    },
    retreat () {
      if (this.stepIndex > 1) {
        this.stepIndex--
      }
    }
  }
}
</script>

<style lang="sass" scoped>
    @import '~vuetify/src/styles/styles.sass'
    #delivery-steps.v-stepper:not(.v-stepper--vertical)::v-deep
        .v-stepper__label
            display: flex !important
        .v-stepper__step__step
            margin-left: 0
            margin-right: 8px
        .step-active > .v-stepper__wrapper
            overflow: visible
        #deliveryPinCode
            .v-text-field__prefix, input, .v-text-field__suffix
                max-height: 56px
                line-height: 56px
                font-size: 32px
            input
                max-width: 200px
</style>
